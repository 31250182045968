'use client';

import * as z from 'zod';
import { isAfter, parseISO } from 'date-fns';


export const EmailFormSchema = z.object({
  email: z.string().email()
});

export const OrgInviteFormSchema = z.object({
  email: z.string().email(),
  firstname: z
    .string()
    .min(2, { message: 'First name must be at least 2 characters.' })
    .nullable()
    .optional(),
  role: z.string({ required_error: 'Please Select a Role.' })
});

export const OrgSchema = z.object({
  name: z
    .string()
    .min(3, {
      message: 'Org name must be at least 3 characters.'
    })
    .max(24, {
      message: 'Org name must not be longer than 24 characters.'
    }),
  description: z
    .string()
    .min(15, {
      message: 'Description Must be at least 15 characters'
    })
    .optional(),
  industry: z.string().min(3, {
    message: 'You must select an industry'
  }),
  logo: z.string().optional()
});

// Define a schema for a participant
export const ParticipantSchema = z.object({
  id: z.string().optional(), // Participant's ID
  projectId: z.string().optional(), // Project ID the participant belongs to
  userId: z.string().nullable().optional(), // Optional user ID
  firstName: z.string().max(20, 'First name cannot exceed 20 characters').nullable().optional(), // Optional first name
  lastName: z.string().max(20, 'Last name cannot exceed 20 characters').nullable().optional(), // Optional last
  phoneNumber: z.string().nullable().optional(), // Optional phone number
  email: z.string().email('Please enter a valid email address'),
  profileData: z.any().optional(), // Optional profile data, can be JSON or any other type
  matchedUsers: z.array(z.string()).optional(), // Array of matched user IDs
  //status: z.enum(["invite_not_sent", "invite_sent", "accepted", "in_progress", "completed", "declined", "removed"]).default("invite_not_sent"),
  status: z.string().optional(),
  hasSystemSentInvite: z.boolean().optional().default(false),
  invitesSentCount: z.number().optional().default(0),
  createdAt: z.date().optional().nullable(),
  updatedAt: z.date().optional().nullable()
});
// .refine((data) => data.email || data.phoneNumber, {
//   message: "Either phone number or email must be provided.",
//   path: ["email"], // You can also set this to "phoneNumber" to display the error on that field
// });

// Define a schema for a member
export const MemberSchema = z.object({
  id: z.string().optional(), // Member's ID
  projectId: z.string().optional(), // Project ID the member belongs to
  userId: z.string(), // User ID of the member
  role: z.enum(['owner', 'member']).default('member').optional(), // Role of the member
  permission: z.enum(['readonly', 'write']).optional(), // Permission level
  userName: z.string().optional(), // Optional name
  userEmail: z.string().email().optional(), // Optional email
  isFavorite: z.boolean().nullable().optional() // Optional favorite status
});

//Define the schema for project configuration
export const ProjectConfigurationSchema = z
  .object({
    projectId: z.string(),
    status: z
      .enum(["draft", "ready", "active", "completed", "archived"])
      .default("draft"),
    matchingType: z.enum(["smart", "manual"]).default("smart"),
    matchingPriority: z
      .enum(["balanced", "skills", "timezone"])
      .default("balanced")
      .describe("Select the primary factor for matching participants"),
    startDateTime: z
      .string()
      .min(1, "Start date and time is required")
      .transform((str) => str.trim())
      .refine((str) => str !== "", {
        message: "Start date and time cannot be empty"
      })
      .refine(
        (date) => {
          try {
            const parsedDate = parseISO(date);
            return !isNaN(parsedDate.getTime()); // Check if it's a valid date
          } catch {
            return false;
          }
        },
        {
          message: "Start date and time must be a valid date"
        }
      )
      .refine(
        (date) => {
          try {
            return isAfter(parseISO(date), new Date());
          } catch {
            return false;
          }
        },
        {
          message: "Start date and time must be in the future"
        }
      ),
    endDateTime: z
      .string()
      .min(1, "End date and time is required")
      .transform((str) => str.trim())
      .refine((str) => str !== "", {
        message: "End date and time cannot be empty"
      })
      .refine(
        (date) => {
          try {
            const parsedDate = parseISO(date);
            return !isNaN(parsedDate.getTime()); // Check if it's a valid date
          } catch {
            return false;
          }
        },
        {
          message: "End date and time must be a valid date"
        }
      )
      .refine(
        (date) => {
          try {
            return isAfter(parseISO(date), new Date());
          } catch {
            return false;
          }
        },
        {
          message: "End date and time must be after the start date and time"
        }
      ),
    timezone: z
      .string()
      .min(1, "Timezone is required")
      .transform((str) => str.trim())
      .refine((str) => str !== "", {
        message: "Timezone cannot be empty"
      })
      .refine((timezone) => timezone.length >= 5, {
        message: "Please select a valid timezone"
      }),
    groupSize: z.string().default("3-4"),
    autoAssignUnmatchedParticipants: z.boolean().nullable().default(true),
    advancedSettings: z.object({
      strictTimezoneMatching: z.boolean().default(false),
      ethnicDiversity: z.boolean().default(false),
      maxTimezoneGap: z.string().default("3"),
      //: z.string().default("60"),
      formationStrategy: z
        .enum(["balanced", "optimize", "fill"])
        .default("balanced"),
    }),
  })
  .refine(
    (data) => {
      try {
        const startDate = parseISO(data.startDateTime);
        const endDate = parseISO(data.endDateTime);
        return (
          !isNaN(startDate.getTime()) && // Check if dates are valid
          !isNaN(endDate.getTime()) &&
          isAfter(endDate, startDate)
        );
      } catch {
        return false;
      }
    },
    {
      message: "End date and time must be after start date and time",
      path: ["endDateTime"],
    }
  );

// Define a schema for a project
export const ProjectSchema = z.object({
  id: z.string().optional().readonly(),
  owner_user_id: z.string().optional().readonly(),
  org_id: z.string().optional().readonly(),
  name: z
    .string()
    .min(5, { message: 'Project name must be at least 5 characters.' })
    .max(50, { message: 'Project name must not be longer than 50 characters.' }),
  description: z.string().max(200, 'Description cannot exceed 200 characters').optional(),
  startDate: z
    .union([z.string(), z.date().nullable(), z.null()])
    .transform((value) => (typeof value === 'string' ? new Date(value) : value))
    .nullable()
    .optional(),
  endDate: z
    .union([z.string(), z.date().nullable(), z.null()])
    .transform((value) => (typeof value === 'string' ? new Date(value) : value))
    .nullable()
    .optional(),
  allowLateSubmissions: z.boolean().default(false),
  isOwnerFavorite: z.boolean().nullable().default(false),
  isMemberFavorite: z.boolean().nullable().default(false),
  maxParticipants: z.number().nullable().optional(),
  customInstructions: z.string().optional(),
  reminderFrequency: z
    .number()
    .nullable()
    .optional()
    .refine((value) => value === undefined || value <= 30, {
      message: 'Reminder frequency should not exceed 30 times.'
    }),
  //timezone: z.string().optional(),
  categories: z.array(z.string()).optional(),
  accessLevel: z.enum(['public', 'private', 'restricted']).default('public'),
  questions: z.array(z.string()).optional(),
  connection_type: z.string().optional(),
  isActive: z.boolean().default(true),
  isLocked: z.boolean().default(false),
  participants: z.array(ParticipantSchema).nullable().optional(), // Array of participant objects
  members: z.array(MemberSchema).nullable().optional(), // Array of member objects
  role: z.enum(['owner', 'member']).nullable().optional(),
  permission: z.enum(['read', 'write']).nullable().optional(),
  gracePeriod: z.string().optional().nullable(),
  numberOfDaysBeforeDeadline: z.number().optional().nullable(),
  participantsCompletionNotification: z.boolean().optional().nullable(),
  participantCount: z.number().optional().nullable(),
  matching_type: z.string().optional().nullable(),
  status: z.enum(['draft', 'ready', 'active', 'completed', 'archived']).default('draft').optional().nullable(),
  questionCount: z.number().optional().nullable(),
  matchingConfiguration: ProjectConfigurationSchema.optional().nullable(),
});

export const ContactUsSchema = z.object({
  firstname: z.string().min(2, {
    message: 'Firstname must be at least 2 characters.'
  }),
  lastname: z.string().min(2, {
    message: 'Lastname must be at least 2 characters.'
  }),
  email: z.string().email({
    message: 'Please enter a valid email address.'
  }),
  phone: z.string().min(10, {
    message: 'Phone number must be at least 10 characters.'
  }),
  companyName: z.string().min(2, {
    message: 'Company name must be at least 2 characters.'
  }),
  companyWebsite: z.string().min(2, {
    message: 'Company website must be at least 2 characters.'
  }),
  message: z.string().min(10, {
    message: 'Message must be at least 10 characters '
  })
});

export const NewsletterSubscribersSchema = z.object({
  email: z.string().email('Please enter a valid email address'),
});


export const ProfileFormSchema = z.object({
  displayName: z
    .string()
    .max(30, { message: "Display name cannot exceed 30 characters." })
    .transform((value) => (value === "" ? null : value))
    .nullable()
    .optional(),
  email: z
    .string()
    .min(1, { message: "Email is required." })
    .email("This is not a valid email.").optional(),
  language: z.string().min(1, { message: "Please select a language." }).default('en'),
  emailNotifications: z.record(
    z.string(),
    z.record(z.string(), z.boolean())
  ),
  emailDigestFrequency: z.enum(["instant", "daily", "weekly", "monthly"]).default("instant"),
  avatar: z.string().optional().nullable(),
  avatarPath: z.string().optional().nullable(),
});

export const QuestionFormSchema = z.object({
  id: z.string().optional().nullable(),
  title: z.string().min(1, "Question title is required"),
  is_multi_select: z.boolean().default(false),
  is_recommended: z.boolean().default(false).nullable(),
  is_SystemQuestion: z.boolean().default(false).nullable(),
  owner_id: z.string().optional().nullable(),
  description_seeker: z.string().min(1, "Seeker description is required"),
  description_seeking: z.string().min(1, "Seeking description is required"),
  profile_type_id: z.string().min(1, "Profile type is required"),
  org_id: z.string(),
  options: z.array(z.string())
    .min(3, "The question must have at least 3 options")
    .max(5, "The question cannot have more than 5 options"),
  is_private: z.boolean().default(false),
});


//export type DisplayNameFormValues = z.infer<typeof DisplayNameFormSchema>;
export type EmailFormValues = z.infer<typeof EmailFormSchema>;
export type OrgInvite = z.infer<typeof OrgInviteFormSchema>;
export type Org = z.infer<typeof OrgSchema>;
export type Project = z.infer<typeof ProjectSchema>;
export type Participant = z.infer<typeof ParticipantSchema>;
export type Member = z.infer<typeof MemberSchema>;
export type ContactUs = z.infer<typeof ContactUsSchema>;
export type NewsletterSubscribers = z.infer<typeof NewsletterSubscribersSchema>;
export type ProjectConfiguration = z.infer<typeof ProjectConfigurationSchema>;
export type Profile = z.infer<typeof ProfileFormSchema>;
export type Question = z.infer<typeof QuestionFormSchema>;
