import {
  Check,
  Laptop,
  Home,
  Settings,
  AlignLeftIcon,
  Mail,
  Command,
  X,
  Loader2,
  EyeIcon,
  EyeOffIcon,
  CircleDollarSign,
  Users,
  ScreenShare,
  User,
  Lock,
  CheckCircle2,
  Sun,
  Moon,
  Twitter,
  Github,
  Linkedin,
  Menu,
  ChevronLeft,
  Bot,
  Unplug,
  Landmark,
  PlusSquare,
  Save,
  UserPlus,
  Upload,
  Trash,
  Send,
  FileQuestion,
  Clock,
  UserCog2,
  LogOut,
  ArrowRight,
  StopCircle
} from 'lucide-react';

const Google = () => (
  // <svg role="img" className="h-4 w-4" viewBox="0 0 24 24" {...props}>
  //   <path
  //     fill="currentColor"
  //     d="M12.48 10.92v3.28h7.84c-.24 1.84-.853 3.187-1.787 4.133-1.147 1.147-2.933 2.4-6.053 2.4-4.827 0-8.6-3.893-8.6-8.72s3.773-8.72 8.6-8.72c2.6 0 4.507 1.027 5.907 2.347l2.307-2.307C18.747 1.44 16.133 0 12.48 0 5.867 0 .307 5.387.307 12s5.56 12 12.173 12c3.573 0 6.267-1.173 8.373-3.36 2.16-2.16 2.84-5.213 2.84-7.667 0-.76-.053-1.467-.173-2.053H12.48z"
  //   />
  // </svg>
  <svg xmlns="http://www.w3.org/2000/svg"
width="0.98em"
height="1em"
viewBox="0 0 256 262">
    <path
      fill="#4285f4"
      d="M255.878 133.451c0-10.734-.871-18.567-2.756-26.69H130.55v48.448h71.947c-1.45 12.04-9.283 30.172-26.69 42.356l-.244 1.622l38.755 30.023l2.685.268c24.659-22.774 38.875-56.282 38.875-96.027"
    />
    <path
      fill="#34a853"
      d="M130.55 261.1c35.248 0 64.839-11.605 86.453-31.622l-41.196-31.913c-11.024 7.688-25.82 13.055-45.257 13.055c-34.523 0-63.824-22.773-74.269-54.25l-1.531.13l-40.298 31.187l-.527 1.465C35.393 231.798 79.49 261.1 130.55 261.1"
    />
    <path
      fill="#fbbc05"
      d="M56.281 156.37c-2.756-8.123-4.351-16.827-4.351-25.82c0-8.994 1.595-17.697 4.206-25.82l-.073-1.73L15.26 71.312l-1.335.635C5.077 89.644 0 109.517 0 130.55s5.077 40.905 13.925 58.602z"
    />
    <path
      fill="#eb4335"
      d="M130.55 50.479c24.514 0 41.05 10.589 50.479 19.438l36.844-35.974C195.245 12.91 165.798 0 130.55 0C79.49 0 35.393 29.301 13.925 71.947l42.211 32.783c10.59-31.477 39.891-54.251 74.414-54.251"
    />
  </svg>
);

export const Icons = {
  SidebarToggle: AlignLeftIcon,
  Laptop,
  Settings,
  Home,
  User,
  Mail,
  Command,
  Close: X,
  Check,
  Spinner: Loader2,
  CircleDollarSign,
  Users,
  ScreenShare,
  EyeIcon,
  EyeOffIcon,
  Lock,
  Google,
  CheckCircle2,
  Sun,
  Moon,
  Menu,
  ChevronLeft,
  Bot,
  Unplug,
  Landmark,
  PlusSquare,
  Save,
  UserPlus,
  Upload,
  Trash,
  Send,
  FileQuestion,
  Clock,
  UserCog2,
  LogOut,
  ArrowRight,
  StopCircle
};

export const SocialIcons = {
  Twitter,
  Google,
  Linkedin,
  Github
};

export const MarketingIcons = {};
